<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Septiembre 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">

    <!--Snackbar de elemento exitoso-->
    <v-snackbar v-model="snackbarValue" :color="snackbarColor ? 'success' : 'error'" timeout="2000">
      {{ snackbarMessage }}
    </v-snackbar>

    <Loading :loading="isLoadingEvaluation"/>
    
    <!-- FILTROS -->
    <section class= "d-grid">
      <section class= "d-flex">
        <!--Colaborador Evaluado-->
        <FilterCustomized
          v-model="buscar.idColaborador"
          :items="listaBusqueda.colaborador" item-value="id" item-text="name" label="Evaluado" :style="{ maxWidth: '37' }"
          @update:search-input= "(value) =>llenarLista('colaborador',listaBusqueda.colaborador,value,'idColaborador','nombre',false)"
          @input="filtrarItems()"
        />
        <!--Periodo de Evaluación-->
        <FilterCustomized
          v-model="buscar.idListaEvaluacion" 
          :items="listaBusqueda.listaEvaluacion" item-value="id" item-text="name" label="Evaluación" :style="{ maxWidth: '23%' }"
          @update:search-input= "(value) =>llenarLista('lista-evaluacion',listaBusqueda.listaEvaluacion,value,'idListaEvaluacion','descripcion',true)"
          @input="filtrarItems()"
        />
        <FilterCustomized
          v-model="buscar.estadoEvaluacionValue" 
          :items="listaBusqueda.estadoEvaluacion" item-value="id" item-text="name" label="Estado Evaluación" :style="{ maxWidth: '20%' }"
          @input="filtrarItems()"
        />
        <FilterCustomized
          v-model="buscar.rolEvaluadorValue" 
          :items="listaBusqueda.rolEvaluador" item-value="id" item-text="name" label="Rol Evaluador" :style="{ maxWidth: '20%' }"
          @input="filtrarItems()"
        />
        </section>
        
    </section>

    <!--Dialogo para Diligenciar una evaluación de desempeño -->
    <v-dialog v-model="isDialogEditOpen" transition="dialog-bottom-transition" :max-width="tab == 0 ? '60rem': '80rem'" persistent> 
      <Loading :loading="isLoadingEvaluation"/>
      <v-card>
        <v-card style="max-height: 79vh">
          <v-tabs v-model="tab" background-color="blue darken-4" dark>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab :key=0>{{crearItem.listaEvaluacion.descripcion}}</v-tab>
            <v-tab :key=1>{{'Plan de Desarrollo'}}</v-tab>
          </v-tabs>
          <v-card-text style="max-height: 73vh; overflow: auto" class="pt-0 pr-0 pl-0 ">
            <v-tabs-items v-model="tab">
              <v-tab-item :key=0>
                <!--Componente que renderiza la evaluación de desempeño-->
                <PerformanceForm
                  :crearItem="crearItem"
                  :crearNominaciones="crearNominaciones"
                  :crearReemplazos="crearReemplazos"
                  :listaEdicion="listaEdicion"
                  :rolEvaluador="rolEvaluador"
                  :setGruposPreguntas="setGruposPreguntas"
                  :estadoEvaluacion="estadoEvaluacion"
                  :resultados="resultados"
                  :tablaNominaciones="tablaNominaciones"
                  :tablaReemplazos="tablaReemplazos"
                  :llenarLista="llenarLista"
                  :listar="listar"
                  :permisosHR="permisosHR"
                  :permisosEvaluador="permisosEvaluador"
                />
              </v-tab-item>
              <v-tab-item :key=1>
                <!--Componente que renderiza el plan de desarrollo-->
                <TablaPlanDesarrollo :crearItem="crearItem" :verFiltros="false" :key="tab"></TablaPlanDesarrollo>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
        <div class="d-flex justify-end  mt-2">
          <v-btn class="me-2 mb-2 mt-1" color="error" @click="clear()" text>
            Cerrar
          </v-btn>
          <v-btn class="mb-2 mt-1" color="info" @click="editarItem('I')" text>
            Guardar Parcial
          </v-btn>
          <v-btn class="mb-2 mt-1" :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" @click="editarItem('T')" text>
            Guardar Definitivo
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" fixed-header
      :headers="tabla.headers" :footer-props="tabla.footerProps" :items-per-page="tabla.itemsPerPage"
      :page="tabla.page" @update:items-per-page="(i) => tabla.itemsPerPage = i" @update:page="(p) => tabla.page = p"
      :server-items-length="tabla.totalPage" height="64vh" @mouseenter="isMenuOpen = false">

      <template v-slot:[`item.nombre`]="{ item }">
        <span>{{ `${item.colaborador.nombre} ${item.colaborador.apellidos}` }}</span>
        <span>- {{ item.cargoAct.nombre }} </span>
      </template>

      <template v-slot:[`item.evaluador`]="{ item }">
        <span>{{ item.rolEvaluador }} </span>
      </template>

      <template v-slot:[`item.resumenResultados`]="{ item }">
        <v-card class="d-grid">
          <v-card :color="defineColor(item.nineBox)" style="color: aliceblue;">
            9Box: {{ item.nineBox ? item.nineBox : 'NA' }}</v-card>
          <div>Desempeño: {{ item.desempeno ? item.desempeno : 'NA' }}</div>
          <div>Potencial: {{ item.potencial ? item.potencial : 'NA' }}</div>
          <div>General: {{ item.resultadoGeneral ? item.resultadoGeneral.toFixed(2) : 'NA' }}</div>
        </v-card>
      </template>

      <template v-slot:[`item.estado`]="{ item }">
        <div class="d-grid">
          <v-icon :color="defineEstado(item.estadoEvaluacionValue).color">
            {{ defineEstado(item.estadoEvaluacionValue).icon }}
          </v-icon>
          <div>
            {{ defineEstado(item.estadoEvaluacionValue).value }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom :color="(item.estadoEvaluacionValue === 'A' || item.estadoEvaluacionValue === 'T') ? 'blue' : `orange`">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-on="on" v-bind="attrs" class="ms-2 me-2" @click="abrirDialogoEditar(item)" >
              <v-icon medium :color="(item.estadoEvaluacionValue === 'A' || item.estadoEvaluacionValue === 'T') ? 'blue' : `orange`">{{ (item.estadoEvaluacionValue === 'A' || item.estadoEvaluacionValue === 'T') ? 'visibility' : `border_color` }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.estadoEvaluacionValue === 'A' ? 'Solo lectura (Evaluación anulada)' : item.estadoEvaluacionValue === 'T' ? `Solo lectura (Evaluación terminada)` : 'Diligenciar Evaluación' }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterCustomized from "@/components/human-talent/FilterCustomized.vue"
import PerformanceForm from "@/components/human-talent/PerformanceForm.vue";
import { required } from "../../../../validators/validations";
import TablaPlanDesarrollo from "@/components/human-talent/TablaPlanDesarrollo.vue";
import Loading from "../../../../components/human-talent/Loading.vue";

export default {
  name:"datos",
  components: {
    FilterCustomized,
    PerformanceForm,
    TablaPlanDesarrollo,
    Loading
  },
  data(){
    return {
      ruta:"msa-hr/api/evaluacion",
      lastmodifiedby:"",
      sincEnterprise:[1],
      userRoles : {},
      rules: {
        required: [required]
      },
      tab: null,
      snackbarValue: false,
      snackbarMessage: '',
      snackbarColor:false,
      isMenuOpen : false,
      isDialogCreateOpen:false,
      isDialogEditOpen:false,
      isLoadingEvaluation:false,
      permisosHR:false,
      permisosEvaluador:false,
      nombreItem : 'Evaluación de Desempeño',
      idEvaluador:null,
      setGruposPreguntas:[],
      buscar:{
        idColaborador:null,
        idListaEvaluacion:null,
        estadoEvaluacionValue:null,
        rolEvaluadorValue:null
      },
      crear:{
        idColaborador:null,
        idEvaluador:null,
        idListaEvaluacion:null,
        idProceso:null,
        idGrupoProceso:null,
        idUbicacionColaborador:null,
      },
      preguntas:[
        {
          idPregunta:null,
          descripcion:null
        }
      ],
      crearItem:{
        colaborador:{
          idColaborador:null,
        },
        cargoAct:{
          idCargo:null,
        },
        evaluador:{
          idEvaluador:null,
        },
        cargoEvaluador:{
          idCargo:null,
        },
        proceso:{
          idProceso:null,
        },
        grupoProceso:{
          idGrupoProceso:null,
          proceso:{
            idProceso:null,
          }
        },
        ubicacionColaborador:{
          idUbicacionColaborador:null
        },
        listaEvaluacion:{
          idListaEvaluacion:null,
        },
        respuestaPregunta:[{
          idRespuestaPregunta:null,
          calificacion:null,
          pregunta:{
            idPregunta:null,
            descripcion:null,
            grupoPreguntaValue:null
          }
        }],
        respuestaCompetencia:[],
        nineBox:null,
        nineBoxLetras:null,
        nineBoxDescripcion:null,
        potencial:null,
        potencialLetras:'',
        desempeno:null,
        desempenoLetras:'',
        resultadoGeneral:null,
        obsPositivos:'',
        obsMejorar:'',
        rolEvaluadorValue:null,
        estadoEvaluacionValue:null,
        nombreCompleto:"",
        nombreEvaluador:""
      },
      crearNominaciones:[],
      crearReemplazos:[],
      listaBusqueda:{
        colaborador:[],
        evaluador:[],
        listaEvaluacion:[],
        proceso:[],
        grupoProceso:[],
        ubicacionColaborador:[],
        estadoEvaluacion:[],
        rolEvaluador:[]
      },
      listaEdicion:{
        cargoAct:[],
        cargoEvaluador:[],
        proceso:[],
        grupoProceso:[],
        ubicacionColaborador:[],
      },
      estadoEvaluacion:[
          {name:'PENDIENTE', id:'P'},
          {name:'INICIADO', id:'I'},
          {name:'TERMINADO', id:'T'},
        ],
      rolEvaluador:[
        {name:'LIDER', id:'L'},
        {name:'AUTOEVALUACIÓN', id:'A'},
        {name:'COLEGA', id:'C'},
        {name:'SUBALTERNO', id:'S'},
      ],
      resultados:{
        headers:[ {text:"Variable",  sortable: false, value:"variable"},
                  {text:"Calificación",  sortable: false, value:"calificacion"}],
        items:[
          {
            variable: "Desempeño",
            calificacion: '',
          },
          {
            variable: "Potencial",
            calificacion:''
          },
          {
            variable: "Califiación General",
            calificacion:''
          }
        ]
      },
      tablaNominaciones:{
        headers:[
          {text:"Tiempo", width: "30%", align: "center", sortable: false, value:"tiempo"},
          {text:"Nombre del Cargo que podría ocupar a través del tiempo", width: "70%",  sortable: false, value:"id"}
        ],
        items:[
          {
            tiempo: "Inmediato",
            lista:[{}],
            id:0,       
          },
          {
            tiempo: "de 1 a 2 años",
            lista:[{}],
            id:1
          },
          {
            tiempo: "de 2 a 4 años",
            lista:[{}],
            id:2
          },
          {
            tiempo: "más de 4 años",
            lista:[{}],
            id:3
          }
        ]
      },
      tablaReemplazos:{
        headers:[
          {text:"Tiempo", width: "30%", align: "center",  sortable: false, value:"tiempo"},
          {text:"Persona que podria reemplazar esta posición", width: "70%",  sortable: false, value:"id"}
        ],
        items:[
          {
            tiempo: "Inmediato",
            lista:[{}],
            id:0,
          },
          {
            tiempo: "de 1 a 2 años",
            lista:[{}],
            id:1
          },
          {
            tiempo: "de 2 a 4 años",
            lista:[{}],
            id:2
          },
          {
            tiempo: "más de 4 años",
            lista:[{}],
            id:3
          }
        ]
      },
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "NOMBRE", width: "30%", sortable: false, align: "center", value: "nombre" },
          { text: "RESULTADOS", width: "12%", sortable: false, align: "center", value: "resumenResultados" },
          { text: "ROL DEL EVALUADOR", width: "10%", sortable: false, align: "center", value: "evaluador" },
          { text: "EVALUACIÓN", width: "14%", sortable: false, align: "center", value: "listaEvaluacion.descripcion" },
          { text: "ESTADO", width: "7%", sortable: false, align: "center", value: "estado"},
          { text: "ACCIONES", width: "7%", sortable: false, align: "center", value: "acciones"},
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
    }
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    formValid() {
      const { obsMejorar, obsPositivos, respuestaCompetencia, respuestaPregunta } = this.crearItem;
      const competenciasCompletas = !respuestaCompetencia.some(respuesta => !respuesta.calificacion)
      const preguntasCompletas = !respuestaPregunta.some(respuesta => !respuesta.calificacion)
      return !!obsMejorar && !!obsPositivos && competenciasCompletas && preguntasCompletas;
    },
    formValid2() {
      const { estadoEvaluacionValue } = this.crearItem;
      return !!estadoEvaluacionValue;
    },
    estado() {
      return !this.crearItem.eliminado
        ? `¿Desea activar ${this.nombreItem}?`
        : `¿Desea inactivar ${this.nombreItem}?`;
    },

  },
  watch: {
    // Escucha los cambios en la página actual de la tabla de items.
    // Si cambia, llama a la función para listar las items.
    "tabla.page": function () {
      this.listarItems();
    },
    // Escucha cambios en la cantidad de ítems por página en la tabla de items.
    // Si cambia, reinicia la página actual a 1 y lista los items.
    "tabla.itemsPerPage": function () {
      this.tabla.page = 1;
      this.listarItems();
    },
  },
  methods: {
    //Guarda una evaluación de desempeño Parcial (P) o Definitiva (D) según el parámetro ingresado
    async editarItem(estadoEvaluacionValue){
      this.isLoadingEvaluation=true;
      const respuestaPregunta = [];
      this.crearItem.respuestaPregunta.forEach(item =>{
        const nuevaRespuestaPregunta = {
          idRespuestaPregunta:item.idRespuestaPregunta,
          pregunta:{
            idPregunta:item.pregunta.idPregunta
          },
          calificacion:item.calificacion
        }
        respuestaPregunta.push(nuevaRespuestaPregunta);
      })

      const respuestaCompetencia = [];
      this.crearItem.respuestaCompetencia.forEach(item => {
        const nuevaRespuestaCompetencia = {
          idRespuestaCompetencia:item.idRespuestaCompetencia,
          competenciaDescriptor:{
            idCompetencia:item.competenciaDescriptor.competencia.idCompetencia,
            nivel:item.competenciaDescriptor.nivel
          },
          calificacion:item.calificacion
        }
        respuestaCompetencia.push(nuevaRespuestaCompetencia);
      })

      const itemEvaluacion = {
        idEmpresa:this.enterprise.code,
        idEvaluacionDesempeno:this.crearItem.idEvaluacionDesempeno,
        idColaborador:this.crearItem.colaborador.idColaborador,
        idEvaluador:this.crearItem.evaluador.idColaborador,
        idCargoAct: this.crearItem.cargoAct.idCargo,
        idCargoEvaluador: this.crearItem.cargoEvaluador.idCargo,
        rolEvaluadorValue: this.crearItem.rolEvaluadorValue,
        idGrupoProceso:this.crearItem.grupoProceso.idGrupoProceso,
        idUbicacionColaborador:this.crearItem.ubicacionColaborador.idUbicacionColaborador,
        idListaEvaluacion:this.crearItem.listaEvaluacion.idListaEvaluacion,
        desempeno:this.crearItem.desempeno,
        potencial:this.crearItem.potencial,
        nineBox:this.crearItem.nineBox,
        resultadoGeneral:this.crearItem.resultadoGeneral,
        segmentos:this.crearItem.segmentos,
        obsPositivos:this.crearItem.obsPositivos,
        obsMejorar:this.crearItem.obsMejorar,
        respuestaPregunta,
        respuestaCompetencia,
        lastmodifiedby : this.auth.username.toUpperCase(),
        estadoEvaluacionValue
      }

      const itemNominacion = [];
      this.crearNominaciones.filter(nominacion => nominacion.cargoNominado.idCargo).forEach(nominacion =>
        {
          let nuevaNominacion = {
            idEmpresa:this.enterprise.code,
            idColaborador: nominacion.colaborador.idColaborador,
            idCargoNominado:nominacion.cargoNominado.idCargo,
            periodoCambioValue:nominacion.periodoCambioValue,
            createdby: this.auth.username.toUpperCase(),
            lastmodifiedby: this.auth.username.toUpperCase()
          }
          itemNominacion.push(nuevaNominacion)
        }
      )

      const itemReemplazo = [];
      this.crearReemplazos.filter(reemplazo => reemplazo.colaboradorReemplazo.idColaborador).forEach(reemplazo =>
        {
          let nuevoReemplazo = {
            idEmpresa: this.enterprise.code,
            idColaborador:reemplazo.colaborador.idColaborador,
            idColaboradorReemplazo: reemplazo.colaboradorReemplazo.idColaborador,
            periodoCambioValue:reemplazo.periodoCambioValue,
            createdby: this.auth.username.toUpperCase(),
            lastmodifiedby: this.auth.username.toUpperCase()
          }
          itemReemplazo.push(nuevoReemplazo)
        }
      )

      try{
        Promise.all([this.$http.post(`${this.ruta}/guardar`,itemEvaluacion),
          this.$http.post(`msa-hr/api/nominacion/guardar`,itemNominacion),
          this.$http.post(`msa-hr/api/reemplazo/guardar`,itemReemplazo)
        ]).then(() => this.listarItems()).then(()=>{
          this.isDialogEditOpen = false;
          this.clear();
          this.snackbarMessage = "Guardado correctamente"
          this.snackbarColor = true;
          this.snackbarValue = true;
          this.isLoadingEvaluation=false;
        }
      )
      }catch(error){
        console.error(error);
        this.snackbarMessage = "Hubo un error al guardar"
        this.snackbarColor = false;
        this.snackbarValue = true;
        this.isLoadingEvaluation=false;
      } 
    },
      /**
     * Método que obtiene y lista los items de la base de datos utilizando criterios de paginación y filtrado.
     * La respuesta obtenida se utiliza para actualizar la tabla de items en el frontend,
     * asignando la lista de items a 'tabla.items' y la cantidad total de items a 'tabla.totalPage'.
     */
    async listarItems() {
      if(this.idEvaluador){         
        this.tabla.loading = true;
        try{
            const response = await this.$http
            .get(`${this.ruta}/listar-activos`, {
              params: {
                idEmpresa: this.enterprise.code,
                idEvaluador: this.idEvaluador,
                idColaborador: this.buscar.idColaborador,
                idListaEvaluacion:this.buscar.idListaEvaluacion,
                rolEvaluadorValue:this.buscar.rolEvaluadorValue,
                estadoEvaluacionValue:this.buscar.estadoEvaluacionValue,
                page: this.tabla.page - 1,
                size: this.tabla.itemsPerPage,
              },
            })
        
          this.tabla.items = response.data.content;
          this.tabla.totalPage = response.data.totalElements;
          this.tabla.loading = false;
  
        }catch(error) {
          console.error(error);
          this.tabla.loading = false;
        }
      }
      
    },
    //Editar evaluaciones de desempeño
    async abrirDialogoEditar(item){
      this.isLoadingEvaluation = true;
      this.crearItem = {
        ...item,
      };

      await Promise.all([
        this.agregarItemAListas(this.crearItem),
        this.agregarNominaciones(item.colaborador.idColaborador),
        this.agregarReemplazos(item.colaborador.idColaborador),
      ]);

      this.permisosEvaluador = !!(this.crearItem.evaluador.idColaborador === this.idEvaluador && this.idEvaluador);
      this.isLoadingEvaluation = false;
      this.isDialogEditOpen = true;
    },
    async filtrarItems(){
      this.tabla.page = 1;
      this.listarItems();
    },
    async agregarItemAListas(item){

      this.crearItem.nombreCompleto = `${item.colaborador.nombre.trim()} ${item.colaborador.apellidos.trim()}`
      this.crearItem.nombreEvaluador = `${item.evaluador.nombre.trim()} ${item.evaluador.apellidos.trim()}`
      this.listaEdicion.proceso=[{id:item.grupoProceso.proceso.idProceso,name:item.grupoProceso.proceso.nombre}]
      this.listaEdicion.grupoProceso=[{id:item.grupoProceso.idGrupoProceso,name:item.grupoProceso.nombre}]
      this.listaEdicion.ubicacionColaborador=[{id:item.ubicacionColaborador.idUbicacionColaborador,name:item.ubicacionColaborador.nombre.trim()}]
      this.listaEdicion.cargoAct=[{id:item.cargoAct.idCargo,name:item.cargoAct.nombre.trim()}]
      this.listaEdicion.cargoEvaluador=[{id:item.cargoEvaluador.idCargo,name:item.cargoEvaluador.nombre.trim()}]
      
      //Crea preguntas si estas no existen
      if(item.respuestaPregunta.length === 0){

          const preguntas = await this.listar('','pregunta',true,null,null,200)

          preguntas.forEach((pregunta,index) => {
              item.respuestaPregunta.push({pregunta:{grupoPregunta:{}}})
              item.respuestaPregunta[index].calificacion = null;
              item.respuestaPregunta[index].pregunta.idPregunta = pregunta.idPregunta;
              item.respuestaPregunta[index].pregunta.descripcion = pregunta.descripcion;
              item.respuestaPregunta[index].pregunta.grupoPregunta.descripcion = pregunta.grupoPregunta.descripcion;
              item.respuestaPregunta[index].pregunta.grupoPregunta.tipoPreguntaValue = pregunta.grupoPregunta.tipoPreguntaValue;
            }
          )
      }else{

        const preguntas = await this.listar('','pregunta',null,null,5000)
        item.respuestaPregunta.forEach(respuesta => {
          const newPregunta = preguntas.filter(pregunta => pregunta.idPregunta == respuesta.pregunta.idPregunta)[0];
          if(newPregunta){
            respuesta.pregunta = newPregunta;
          }
        })
      }

      this.setGruposPreguntas = item.respuestaPregunta.filter((obj, index, self) =>
            index === self.findIndex((t) => t.pregunta.grupoPregunta.descripcion === obj.pregunta.grupoPregunta.descripcion)
          );

      //Crea respuestas para competencias si estas no existen
      if(item.respuestaCompetencia.length === 0){
        const cargo = await this.listar('','cargo',true,item.cargoAct.codStone);
        const { competenciasDescriptores } = cargo[0];
        competenciasDescriptores.forEach((competenciaDescriptor,index)=>{
          item.respuestaCompetencia.push({competenciaDescriptor:{competencia:{}}});
          item.respuestaCompetencia[index].calificacion = null;
          item.respuestaCompetencia[index].competenciaDescriptor.competencia.idCompetencia = competenciaDescriptor.competencia.idCompetencia;
          item.respuestaCompetencia[index].competenciaDescriptor.competencia.nombre  = competenciaDescriptor.competencia.nombre;
          item.respuestaCompetencia[index].competenciaDescriptor.competencia.tipoCompetenciaValue =  competenciaDescriptor.competencia.tipoCompetenciaValue;
          item.respuestaCompetencia[index].competenciaDescriptor.nivel = competenciaDescriptor.nivel;
          item.respuestaCompetencia[index].competenciaDescriptor.descripcion = competenciaDescriptor.descripcion;
        })

        let maxIndex = item.respuestaCompetencia.length;

        const competenciasOrganizacinales = await this.listar('','competencia',true,undefined,"O");

        competenciasOrganizacinales.forEach((competenciaOrganizacinal)=>{
          if(!item.respuestaCompetencia.some(r => r.competenciaDescriptor.competencia.idCompetencia === competenciaOrganizacinal.idCompetencia)){
            item.respuestaCompetencia.push({competenciaDescriptor:{competencia:{}}});
            item.respuestaCompetencia[maxIndex].calificacion = null;
            item.respuestaCompetencia[maxIndex].competenciaDescriptor.competencia.idCompetencia = competenciaOrganizacinal.idCompetencia;
            item.respuestaCompetencia[maxIndex].competenciaDescriptor.competencia.nombre  = competenciaOrganizacinal.nombre;
            item.respuestaCompetencia[maxIndex].competenciaDescriptor.competencia.tipoCompetenciaValue =  competenciaOrganizacinal.tipoCompetenciaValue;
            item.respuestaCompetencia[maxIndex].competenciaDescriptor.nivel = 4;
            item.respuestaCompetencia[maxIndex].competenciaDescriptor.descripcion = competenciaOrganizacinal.descripcion;
            maxIndex++;
          }
        })
      }

      this.resultados.items[0].calificacion = item.desempeno ? (item.desempeno == 1 ? 'Bajo' : item.desempeno == 2 ? 'Medio' : item.desempeno == 3 ? 'Alto': 'NA') : 'NA';
      this.resultados.items[1].calificacion = item.potencial ? (item.potencial == 1 ? 'Bajo' : item.potencial == 2 ? 'Medio' : item.potencial == 3 ? 'Alto' : 'NA') : 'NA';
      this.resultados.items[2].calificacion = item.resultadoGeneral || 'NA';

    },
    //Funcion para agregar nominaciones de acuerdo con un colaborador
    async agregarNominaciones(idColaborador){
      //Busca en el back las entidades nominaciones asociadas al colaborador evaluado
      const lista = await this.listarNominacionReemplazo('nominacion',idColaborador);
      this.crearNominaciones.length = 0;
     //crea la tabla nominaciones que se  muestra en el back
      for ( let i = 0; i<4; i++){
        this.tablaNominaciones.items[i].lista[0] = {id:'',name:''};
      }
      
      const periodos = ['A','B','C','D']
      //Asigna los valores a crearNominaciones que posteriomente se usará para guardar el array de nominaciones en BD
      periodos.forEach( periodo => {
        
        let nominacion = lista.filter(nominacion => nominacion.periodoCambioValue == periodo)[0]

        if(nominacion){
          this.crearNominaciones.push(nominacion)
        }else{
          this.crearNominaciones.push({
            empresa:{idEmpresa:this.enterprise.code},
            colaborador:{idColaborador},
            periodoCambioValue:periodo,
            cargoNominado:{idCargo:null}
          })
        }
      })
      //Agrega las nominaciones existentes en la tabla que se muestra en el Front
      for(let i = 0; i < 4 ; i++){
        if(this.crearNominaciones[i].cargoNominado.idCargo){
          this.tablaNominaciones.items[i].lista[0].id = this.crearNominaciones[i].cargoNominado.idCargo;
          this.tablaNominaciones.items[i].lista[0].name = this.crearNominaciones[i].cargoNominado.nombre.trim();
        }
      }    
    },

    //Funcion para agregar Reemplazos de acuerdo con un colaborador
    async agregarReemplazos(idColaborador){
      //Busca la lista de nominaciones desde el Back
      const lista = await this.listarNominacionReemplazo('reemplazo',idColaborador);
      this.crearReemplazos.length = 0;
      //Crea la tabla de reemplazos (inicialmente vacía)
      for ( let i = 0; i<4; i++){
        this.tablaReemplazos.items[i].lista[0] = {id:'',name:''};
      }

      const periodos = ['A','B','C','D']
      //Crea el Array de reemplazos que luego se usará para crear la entidad en la BD 
      periodos.forEach( periodo => {

        let reemplazo = lista.filter(reemplazo => reemplazo.periodoCambioValue == periodo)[0]

        if(reemplazo){
          this.crearReemplazos.push(reemplazo)
        }else{
          this.crearReemplazos.push({
            empresa:{idEmpresa:this.enterprise.code},
            colaborador:{idColaborador},
            periodoCambioValue:periodo,
            colaboradorReemplazo:{idColaborador:null}
          })
        }
      })
      //Asigna los valores de crearReemplazos a la tabla que se muestra en el front
      for(let i = 0; i < 4 ; i++){
        if(this.crearReemplazos[i].colaboradorReemplazo.idColaborador){
          this.tablaReemplazos.items[i].lista[0].id = this.crearReemplazos[i].colaboradorReemplazo.idColaborador;
          this.tablaReemplazos.items[i].lista[0].name = `${this.crearReemplazos[i].colaboradorReemplazo.nombre.trim()} ${this.crearReemplazos[i].colaboradorReemplazo.apellidos.trim()}`;
        }
      }
    },
    //Manejo de solicitudes al BackEnd
    async listar(nombre, Url, soloActivos,codStone,tipoCompetenciaValue,size=100) {
      let lista = await this.$http.get(`msa-hr/api/${Url}/listar`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombre,
          codStone,
          tipoCompetenciaValue,
          nombreCompleto: nombre,
          eliminado: soloActivos ? !soloActivos : '',
          size: size
        },
      });
      return lista.data.content ? lista.data.content : [];
    },

    //Método genérico para el Llenado de una lista para filtro
    async llenarLista(Url,listado,nombreBusqueda, nombreId, nombreItem = "nombre", soloActivos) {
      //Trae la lista desde el back
      const lista = await this.listar(nombreBusqueda, Url,soloActivos);
      // Vaciar el listado original
      listado.length = 0;
      // Llenar el listado con los nuevos elementos
      lista.forEach(item => {
        listado.push({
          name: `${item[nombreItem].trim()}${item.apellidos? ` ${item.apellidos.trim()}` : ''}` ,
          id: item[nombreId]
        });
      });
    },
    //Manejo de solicitudes al BackEnd de Nominaciones y Reemplazos
    async listarNominacionReemplazo(Url, idColaborador) {
      const lista = await this.$http.get(`msa-hr/api/${Url}/listar`, {
        params: {
          idEmpresa: this.enterprise.code,
          idColaborador,
          size: 4
        },
      });
      return lista.data.content ? lista.data.content : [];
    },
    //Define Color para mostrar en la tabla de evaluación de desempeño
    defineColor(nineBox){
      switch(nineBox){
        case 1:
          return 'red';
        case 2:
          return '#366092';
        case 3:
          return 'red';
        case 4:
          return '#92D050';
        case 5:
          return '#0000FF';
        case 6:
          return '#FFC000';
        case 7:
          return '#92D050';
        case 8:
          return '#92D050';
        case 9:
          return '#008000';
        default:
          return 'grey';
      }
    },
    //Genera ícono y color según el estado de evaluación seleccionado
    defineEstado(estado){
      switch(estado){
        case 'A':
          return {icon:'delete_forever',color:'error',value:'Anulado'};
        case 'I':
          return {icon:'timer',color:'info',value:'Iniciado'};
        case 'T':
          return {icon:'check_circle',color:'success',value:'Terminado'};
        default:
          return {icon:'pending',color:'warning',value:'Pendiente'};
      }
    },
    clear(){
      this.isDialogEditOpen = false;
      this.setGruposPreguntas = [];
      if (this.$refs.itemForm) {
        this.$refs.itemForm.resetValidation();
      }
      if (this.$refs.itemForm2) {
        this.$refs.itemForm2.resetValidation();
      }
      Object.keys(this.crear).forEach(key => {
        this.crear[key] = null;
      });
      this.tab = 0;
    },
    allowAcess(){
      if(this.userRoles.includes('TalentoHumano_EvaluacionDesempeno_Datos')){
        this.permisosHR = true;
      }
    },
    async defineEvaluador(user){
      try{
        const evaluador = await this.$http.get(`msa-hr/api/colaborador/listar/${user}`,{
          params:{
            idEmpresa: this.enterprise.code
          }
        })
        if(evaluador.data.length>0){
          this.idEvaluador = evaluador.data[0].idColaborador;
        }
      }catch(error){
        console.error(error)
      }
    }
  },
  async created() {
    this.userRoles = this.auth.roles;
    try{
      await this.defineEvaluador(this.auth.username)
      await this.listarItems();
    }catch(error){
      console.error(error)
    }
    this.allowAcess();
  },
  mounted() {
    this.llenarLista('colaborador',this.listaBusqueda.colaborador,'','idColaborador','nombre',false);
    this.llenarLista('colaborador',this.listaBusqueda.evaluador,'','idColaborador','nombre',false);
    this.llenarLista('lista-evaluacion',this.listaBusqueda.listaEvaluacion,'','idListaEvaluacion','descripcion',true);
    this.llenarLista('grupo-proceso',this.listaBusqueda.grupoProceso,'','idGrupoProceso','nombre',false);
    this.llenarLista('ubicacion',this.listaBusqueda.ubicacionColaborador,'','idUbicacionColaborador','nombre',false);
    this.llenarLista('proceso',this.listaBusqueda.proceso,'','idProceso','nombre',false);
    this.listaBusqueda.estadoEvaluacion = [...this.estadoEvaluacion];
    this.listaBusqueda.rolEvaluador = [...this.rolEvaluador];
    this.lastmodifiedby = this.auth.username.toUpperCase();
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>